/* rm modified */

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

var Check24 = Check24 || {};

Check24 = {
  domain: "",
  vars: [],
  delays: [],
  set(name, value) {
    this.vars[name] = value;
  },
  get(name) {
    return this.vars[name];
  },
  setDomain(domain) {
    this.set("domain", domain);
  },
  getDomain() {
    return typeof this.get("domain") !== "undefined"
      ? this.get("domain")
      : "check24.de";
  },
  getSubDomain() {
    return typeof this.get("domain_sub") !== "undefined"
      ? this.get("domain_sub")
      : "www";
  },
  setHost(host) {
    const hostParts = host.split(".");
    let domain = host;
    let subDomain = "www";
    if (hostParts.length > 2) {
      domain = hostParts
        .slice(hostParts.length - 2, hostParts.length)
        .join(".");
      subDomain = hostParts.slice(0, hostParts.length - 2).join(".");
    }
    this.set("domain", domain);
    this.set("domain_sub", subDomain);
  },
  getHost() {
    return `${this.getSubDomain()}.${this.getDomain()}`;
  },
  setCookie(cookieName, value, duration) {
    if (cookieName && value && duration) {
      const date = new Date();
      date.setTime(date.getTime() + duration);
      document.cookie = `${cookieName}=${value}; expires=${date.toGMTString()}; path=/; domain=${Check24.getDomain()}; SameSite=lax`;
    }
  },
  getCookie(cookieName) {
    if (cookieName) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${cookieName}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
      return "";
    }
  },
  hasClass(ele, className) {
    const el = document.querySelector(ele);
    if (el) {
      if (el.classList) {
        return el.classList.contains(className);
      }
      return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
    }
  },
  addClass: function addClass(element, className) {
    const ele = document.querySelectorAll(element);

    if (ele) {
      ele.forEach(function (el) {
        if (el.classList) {
          el.classList.add(className);
        } else if (!Check24.hasClass(el, className)) {
          el.className += ` ${className}`;
        }
      });
    }
  },
  removeClass: function removeClass(element, className) {
    const ele = document.querySelectorAll(element);

    if (ele) {
      ele.forEach(function (el) {
        if (el.classList) {
          el.classList.remove(className);
        } else if (Check24.hasClass(el, className)) {
          const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
          el.className = el.className.replace(reg, " ");
        }
      });
    }
  },
  show(element, mode) {
    if (Array.isArray(element)) {
      element.forEach(function (elem) {
        const ele = document.querySelectorAll(elem);
        ele.forEach(function (el) {
          if (el) {
            if (mode) {
              if (mode === "visibility") {
                el.style.visibility = "visible";
              } else {
                el.style.display = mode;
              }
            } else {
              el.style.display = "block";
            }
          }
        });
      });
    } else {
      const ele = document.querySelectorAll(element);
      ele.forEach(function (el) {
        if (mode) {
          if (mode === "visibility") {
            el.style.visibility = "visible";
          } else {
            el.style.display = mode;
          }
        } else {
          el.style.display = "block";
        }
      });
    }
  },
  hide(element, mode) {
    if (Array.isArray(element)) {
      element.forEach(function (elem) {
        const ele = document.querySelectorAll(elem);
        ele.forEach(function (el) {
          if (el) {
            if (mode === "visibility") {
              el.style.visibility = "hidden";
            } else {
              el.style.display = "none";
            }
          }
        });
      });
    } else {
      const ele = document.querySelectorAll(element);
      ele.forEach(function (el) {
        if (el) {
          if (mode === "visibility") {
            el.style.visibility = "hidden";
          } else {
            el.style.display = "none";
          }
        }
      });
    }
  },
  exists(element) {
    return document.body.contains(document.querySelector(element));
  },
  closest: function closest(area, element) {
    return document.querySelector(area).closest(element);
  },
  find: function find(area, element, single) {
    // Final found elements
    let found_elements = [];
    let outers = area; // Find all the outer matched elements
    if (single === true) {
      found_elements = outers.querySelector(element);
    } else {
      outers = document.querySelectorAll(area);
      for (let i = 0; i < outers.length; i++) {
        let elements_in_outer = outers[i].querySelectorAll(element);
        elements_in_outer = Array.prototype.slice.call(elements_in_outer);
        found_elements = found_elements.concat(elements_in_outer);
      }
    }
    return found_elements;
  },
  addEvent: function addEvent(ele, event, callback) {
    if (typeof callback !== "function") {
      console.log("addEvent: no callback function given");
      return false;
    }

    if (ele.indexOf(/\./)) {
      const className = document.querySelectorAll(ele);

      for (let i = 0; i < className.length; i++) {
        className[i].removeEventListener(event, callback, false);
        className[i].addEventListener(event, callback, false);
      }
    } else if (ele.indexOf("#")) {
      document.querySelector(ele).removeEventListener(event, callback, false);
      document.querySelector(ele).addEventListener(event, callback, false);
    } else {
      console.log("addEvent: no class or id given");
    }
  },
  removeEvent: function removeEvent(element, event, callback) {
    const el = document.querySelector(element);
    if (el && event && callback) {
      el.removeEventListener(event, callback, false);
    } else {
      console.log("removeEvent: missing parameters");
    }
  },
  getValue: function getValue(element) {
    const ele = document.querySelector(element);

    if (ele) {
      return ele.value;
    }
  },
  change: function change(type, element, value, callback) {
    let ele = document.querySelectorAll(element);
    if (element.indexOf(/\./)) {
      ele.forEach(function (el) {
        if (el) {
          if (type === "value") {
            el.value = value;
          } else if (type === "html") {
            el.innerHTML = value;
          } else if (type === "type") {
            el.type = value;
          } else if (type === "placeholder") {
            el.placeholder = value;
          } else if (type === "href") {
            el.href = value;
          } else {
            console.log("change: missing parameters");
          }
        }
      });
    } else {
      ele = document.querySelector(element);
      if (type === "value") {
        ele.value = value;
      } else if (type === "html") {
        ele.innerHTML = value;
      } else if (type === "type") {
        ele.type = value;
      } else if (type === "placeholder") {
        ele.placeholder = value;
      } else if (type === "href") {
        ele.href = value;
      } else {
        console.log("change: missing parameters");
      }
    }

    if (typeof callback === "function") {
      callback();
    }
  },
};

document.addEventListener(
  "DOMContentLoaded",
  function () {
    if (typeof CHECK24_HOST !== "undefined") {
      Check24.setHost(CHECK24_HOST);
    }
  },
  false
);
document.addEventListener(
  "wireframe-init",
  function () {
    if (typeof CHECK24_HOST !== "undefined") {
      Check24.setHost(CHECK24_HOST);
    }
  },
  false
);

window.C24M = window.C24M || {};
window.C24M.cookieBanner = {
  domain: ".check24.de",
  visible: false,
  init(trigger) {
    // "Legacy case" ("opt-out") for the Spanish products still using the German Wireframe
    // Will be removed once the Spanish products convert to their own Wireframe
    if (document.querySelector(".c24m-cookie")) {
      Check24.removeClass("body", "c24m-cc-visible");
      window.C24M.cookieBanner.initLegacyCookieBanner(trigger);
      return;
    }

    const consentWrapper = document.querySelector(
      ".c24m-cookie-consent-wrapper"
    );
    if (consentWrapper == null) {
      Check24.removeClass("body", "c24m-cc-visible");
      return;
    }

    setTimeout(function checkForLayer() {
      if (consentWrapper != null) {
        const layerStyle = window.getComputedStyle(consentWrapper);
        if (consentWrapper.length === 0 || layerStyle.display === "none") {
          Check24.removeClass("body", "c24m-cc-visible");
        } else {
          setTimeout(checkForLayer, 500);
        }
      } else {
        Check24.removeClass("body", "c24m-cc-visible");
      }
    }, 500);

    // New Cookie Consent Screen case ("opt-in")
    window.C24M.cookieBanner.c24consent.initCookieConsentScreen();
  },
  c24consent: {
    initCookieConsentScreen() {
      const c24consent =
        window.C24M.cookieBanner.c24consent.getCookieWithName("c24consent");
      if (c24consent && c24consent.indexOf("t") === -1) {
        // If c24consent cookie is set & not marked as temporary => everything is fine => we do not have to show a cookie consent screen
        // Instead, we refresh the lifetime of the existing cookie
        window.C24M.cookieBanner.c24consent.setC24consentCookie(c24consent);
        return;
      }

      // If user had a c24consent cookie that is marked as "temporary", remove that
      // => we need to ask him for his consent.
      if (c24consent && c24consent.indexOf("t") !== -1) {
        window.C24M.cookieBanner.c24consent.unsetCookie("c24consent");
      }

      // Special case: In case the user previously closed the legacy cookie banner (c24cb=1)
      //               and did not opt-out to anything (ch24oo not set or ch24oo === '00000000000'),
      //               we set the c24consent value to "fam" and do not show the Cookie Consent Screen.
      const c24cb =
        window.C24M.cookieBanner.c24consent.getCookieWithName("c24cb");
      if (c24cb === "1") {
        const ch24oo =
          window.C24M.cookieBanner.c24consent.getCookieWithName("ch24oo");
        if (ch24oo === null || ch24oo === "00000000000") {
          window.C24M.cookieBanner.c24consent.setC24consentCookie("fam");
          return;
        }
      }

      // Show consent screen notice
      window.C24M.cookieBanner.c24consent.showNoticeScreen();
    },
    giveConsent(consent) {
      Check24.hide([
        ".c24m-cookie-consent-screen",
        ".c24m-cookie-consent-notice",
      ]);
      Check24.removeClass("body", "c24m-cc-visible");

      window.C24M.cookieBanner.c24consent.setC24consentCookie(consent);
      window.C24M.cookieBanner.c24consent.countCookieConsent(consent);
      const consentm = window.C24M.cookieBanner.c24consent.marketingConsent;
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "c24consent",
          c24consent: consent,
          c24consentm: consent.indexOf("m") !== -1 ? "" : consentm,
        });
      }
      window.dispatchEvent(
        new CustomEvent("c24consent", {
          detail: {
            c24consent: consent,
            c24consentm: consent.indexOf("m") !== -1 ? "" : consentm,
          },
        })
      );
    },
    getC24consentCookieDomain() {
      if (window && window.location && window.location.hostname) {
        return window.location.hostname.split(".").slice(-2).join(".");
      }
      return "check24.de";
    },
    setC24consentCookie(value) {
      const content = `c24consent=${value}`;
      const domain = `domain=.${window.C24M.cookieBanner.c24consent.getC24consentCookieDomain()}`;
      const sameSite = "samesite=lax";
      const path = "path=/";

      const d = new Date();
      d.setTime(d.getTime() + 63072000000); /* two years in milliseconds */
      const expires = `expires=${d.toUTCString()}`; /* max-age would have been easier here, but does not work in IE < 10 */

      document.cookie = [content, domain, sameSite, path, expires].join("; ");
      if (value === "fam") {
        document.cookie = [
          "c24consentm=",
          domain,
          sameSite,
          path,
          "expires=Thu, 01 Jan 1970 00:00:00 UTC",
        ].join("; ");
      }
    },
    unsetCookie(name) {
      // Similar to `setC24consentCookie`, but uses a negative expiry time => cookie will be removed
      const content = `${name}=`;
      const domain = `domain=.${window.C24M.cookieBanner.c24consent.getC24consentCookieDomain()}`;
      const sameSite = "samesite=lax";
      const path = "path=/";

      const d = new Date();
      d.setTime(d.getTime() - 63072000000); /* two years in milliseconds */
      const expires = `expires=${d.toUTCString()}`; /* max-age would have been easier here, but does not work in IE < 10 */

      document.cookie = [content, domain, sameSite, path, expires].join("; ");
    },
    showConsentScreen() {
      Check24.hide(".c24m-cookie-consent-notice");
      Check24.show(".c24m-cookie-consent-screen", "flex");
      Check24.addClass("body", "c24m-cc-visible");
    },
    hideConsentScreen() {
      Check24.hide(".c24m-cookie-consent-screen");
      Check24.removeClass("body", "c24m-cc-visible");
    },
    showNoticeScreen() {
      Check24.hide(".c24m-cookie-consent-screen");
      Check24.show(".c24m-cookie-consent-notice", "flex");
      Check24.addClass("body", "c24m-cc-visible");
    },
    getCookieWithName(name) {
      const cookieArr = document.cookie.split(";");

      for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if (cookiePair[0].trim() === name) {
          return cookiePair[1];
        }
      }

      return null;
    },
    toggleDescriptionText(id, iconId) {
      const el = document.getElementById(id);
      if (!el) {
        return;
      }

      if (el.style.display === "none" || el.style.display === "") {
        el.style.display = "block";
        Check24.addClass(
          `#${iconId}`,
          "c24m-cookie-consent-button-more-icon-open"
        );
        const wrapper = document.querySelector(
          ".c24m-cookie-consent-screen-list"
        );
        wrapper.scrollTo(
          0,
          document.getElementById(iconId).offsetTop - wrapper.offsetTop - 15
        );
        return;
      }

      el.style.display = "none";
      Check24.removeClass(
        `#${iconId}`,
        "c24m-cookie-consent-button-more-icon-open"
      );
    },
    toggleCheckbox(id) {
      const checkbox = document.getElementById(id);
      if (!checkbox) {
        return;
      }

      checkbox.checked = !checkbox.checked;

      if (id === "c24m-cookie-checkbox-marketing") {
        for (var i = 1; i <= 5; i++) {
          var ele = document.getElementById(`check24-cookie-marketing-${i}`);
          if (!ele) {
            return;
          }
          if (checkbox.checked) {
            ele.checked = true;
          } else {
            ele.checked = false;
          }
        }
      }
      if (
        checkbox.classList &&
        checkbox.classList.contains("c24m-cookie-consent-m-toggle")
      ) {
        let checkedEles = "";
        for (var i = 1; i <= 5; i++) {
          var ele = document.getElementById(`check24-cookie-marketing-${i}`);
          if (!ele) {
            return;
          }
          if (ele.checked) {
            checkedEles += i;
          }
        }

        const mCheckbox = document.getElementById(
          "c24m-cookie-checkbox-marketing"
        );
        if (checkedEles === "12345") {
          mCheckbox.checked = true;
        } else {
          mCheckbox.checked = false;
        }
      }
    },
    updateConsentScreen() {
      const c24consent =
        window.C24M.cookieBanner.c24consent.getCookieWithName("c24consent");
      const c24consentm =
        window.C24M.cookieBanner.c24consent.getCookieWithName("c24consentm");

      if (c24consent) {
        const analytics = document.getElementById(
          "c24m-cookie-checkbox-analytics"
        );
        if (analytics) {
          analytics.checked = c24consent.indexOf("a") !== -1;
        }
        const marketing = document.getElementById(
          "c24m-cookie-checkbox-marketing"
        );
        if (marketing) {
          if (c24consent.indexOf("m") !== -1) {
            for (var i = 1; i <= 5; i++) {
              var ele = document.getElementById(
                `check24-cookie-marketing-${i}`
              );
              ele.checked = true;
            }
          } else if (c24consentm) {
            for (var i = 1; i <= 5; i++) {
              var ele = document.getElementById(
                `check24-cookie-marketing-${i}`
              );
              ele.checked = c24consentm.indexOf(i) !== -1;
            }
          }
          marketing.checked = c24consent.indexOf("m") !== -1;
        }
      }
    },
    getConfiguredConsent() {
      let result = "f";

      const analytics = document.getElementById(
        "c24m-cookie-checkbox-analytics"
      );
      if (analytics && analytics.checked) {
        result += "a";
      }
      const marketing = document.getElementById(
        "c24m-cookie-checkbox-marketing"
      );
      if (
        marketing &&
        marketing.checked &&
        window.C24M.cookieBanner.c24consent.marketingConsent === "12345"
      ) {
        result += "m";
      }

      return result;
    },
    marketingConsent: "00000",
    giveMarketingConsent() {
      let result = "";
      for (let i = 1; i <= 5; i++) {
        const ele = document.getElementById(`check24-cookie-marketing-${i}`);
        if (ele && ele.checked) {
          result += i;
        }
      }
      window.C24M.cookieBanner.c24consent.marketingConsent = result;
      const content = `c24consentm=${result}`;
      const domain = `domain=.${window.C24M.cookieBanner.c24consent.getC24consentCookieDomain()}`;
      const sameSite = "samesite=lax";
      const path = "path=/";
      const d = new Date();
      d.setTime(d.getTime() + 63072000000);
      const expires = `expires=${d.toUTCString()}`;

      if (result !== "" && result !== "12345") {
        document.cookie = [content, domain, sameSite, path, expires].join("; ");
      } else {
        document.cookie = [
          "c24consentm=",
          domain,
          sameSite,
          path,
          "expires=Thu, 01 Jan 1970 00:00:00 UTC",
        ].join("; ");
      }

      window.C24M.cookieBanner.c24consent.giveConsent(
        window.C24M.cookieBanner.c24consent.getConfiguredConsent()
      );
    },
    countCookieConsent(value) {
      const request = new XMLHttpRequest();

      if (!request) {
        return;
      }

      request.open(
        "GET",
        `https://www.${Check24.getDomain()}/ajax/misc/count-cookie-consent/?c24consent=${value}`
      );
      request.send();
    },
  },
  initLegacyCookieBanner(trigger) {
    const button = document.querySelector(".c24m-cookie-button");
    if (document.querySelector(".c24m-smart-app-banner")) {
      Check24.addClass(".c24m-page", "c24m-smb");
    }
    window.C24M.cookieBanner.domain = `.${Check24.getDomain()}`;
    if (trigger !== true) {
      Check24.removeClass(".c24m-cookie", "c24m-cookie-hide");
    }
    if (window.C24M.cookieBanner.getCookie() != 1 && button) {
      window.C24M.cookieBanner.showBanner(button);
    }
  },
  getCookie() {
    const value = `; ${document.cookie}`;
    const parts = value.split("; " + "c24cb" + "=");
    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
    return "";
  },
  setCookie() {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    document.cookie = `c24cb=1; expires=${date.toGMTString()}; path=/; domain=${
      window.C24M.cookieBanner.domain
    }; SameSite=lax`;
  },
  showBanner(button) {
    C24M.cookieBanner.visible = true;
    Check24.addClass(".c24m-page", "c24m-cookie-transition");
    Check24.addClass(".c24m-page", "c24m-cookie-animate");
    Check24.removeClass(".c24m-cookie", "c24m-cookie-hide");
    setTimeout(function () {
      Check24.removeClass(".c24m-page", "c24m-cookie-transition");
    }, 300);
    button.addEventListener("click", function () {
      window.C24M.cookieBanner.setCookie();
      window.C24M.cookieBanner.hideBanner();
    });
  },
  hideBanner() {
    window.C24M.cookieBanner.visible = false;
    Check24.removeClass(".c24m-page", "c24m-cookie-animate");
    Check24.addClass(".c24m-cookie", "c24m-cookie-hide");
  },
};
